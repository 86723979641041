<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Karyawan'">
          <template v-slot:body>
            <HrDetail :data="data" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Card from '@/view/content/Card.vue'
import HrDetail from '@/component/human-resource/Detail.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  name: 'Detail',

  components: {
    Card,
    HrDetail
  },

  data() {
    return {
      data: [],
      show: true,
      imgLoaded: false
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async getdata(){
      this.data = await module.get('employees/'+this.$route.params.id)
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Karyawan", route: "" },
      { title: "Daftar Karyawan", route: "/human-resource/employee/list" },
      { title: "Detail" },
    ])
    this.getdata()
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>